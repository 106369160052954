import React from "react"
import PropTypes from "prop-types"

import "../styles/concept-section.scss"

const ConceptGrid = ({ conceptChunks, title }) => (
  <section className="concept-section ">
    <div className="section-title">{title}</div>
    {conceptChunks.map((concepts, idx) => (
      <div className="columns" key={idx}>
        {concepts.map((concept, idx) => (
          <div className="column has-text-centered" key={idx}>
            <div className="top">{concept.top}</div>
            <div className="content">{concept.content}</div>
            <div className="bottom">{concept.bottom}</div>
          </div>
        ))}
      </div>
    ))}
  </section>
)

ConceptGrid.propTypes = {
  concepts: PropTypes.array,
  title: PropTypes.node,
}

ConceptGrid.defaultProps = {
  concepts: [],
  title: null,
}

export default ConceptGrid

import React from "react"

import Layout from "../../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../../components/seo"

import Button from "../../components/button"
import MainBanner from "../../components/main-banner"
import ConceptGrid from "../../components/concept-grid"

import MeiquerLabsLogo from "../../assets/logos/logo-meiquer-labs.svg"
import FonnorLogo from "../../assets/logos/logo-fonnor.svg"
import ColmenaLogo from "../../assets/logos/logo-colmena.svg"
import TalenyLogo from "../../assets/logos/logo-taleny.svg"
import ChangeMakerLogo from "../../assets/logos/logo-change-maker.svg"
import MakerFaireLogo from "../../assets/logos/logo-maker-faire.svg"
import RetoZapopanLogo from "../../assets/logos/logo-reto-zapopan.svg"

const mainBannerContent = (
  <>
    <div>
      <img
        style={{ width: "10em" }}
        src={MeiquerLabsLogo}
        alt="Meiquer Labs Logo"
      />
      <p style={{ letterSpacing: ".3rem" }} className="c-turquoise w-bold">
        Historias
      </p>
    </div>
    <p>
      Descubre las historias de los proyectos que diseñamos con nuestros aliados
      y clientes para <span className="w-bold">transformar comunidades</span>,
      jugando.
    </p>
  </>
)

const reasons = [
  [
    {
      top: <img style={{ width: "6em" }} src={FonnorLogo} alt="Fonnor Logo" />,
      content: (
        <>
          <h3>Rugiendo fuerte</h3>
          <p>
            Co-diseñamos talleres para la preservación del jaguar en el Sur de
            Jalisco para el Proyecto MIJO.
          </p>
        </>
      ),
      bottom: (
        <Button linkTo="/servicios-profesionales/fonnor">Leer Más</Button>
      ),
    },
    {
      top: (
        <img
          style={{ width: "6em" }}
          src={RetoZapopanLogo}
          alt="Reto Zapopan Logo"
        />
      ),
      content: (
        <>
          <h3>Inventando juntos</h3>
          <p>
            Implementamos Reto Kids, un programa de emprendimiento e innovación
            para niñas y niños.
          </p>
        </>
      ),
      bottom: (
        <Button linkTo="/servicios-profesionales/reto-kids">Leer Más</Button>
      ),
    },
    {
      top: (
        <img style={{ width: "6em" }} src={ColmenaLogo} alt="Colmena Logo" />
      ),
      content: (
        <>
          <h3>Imaginando el futuro</h3>
          <p>
            Diseñamos un taller con COLMENA sobre el transporte del futuro para
            las niñas y niños de AstraZeneca.
          </p>
        </>
      ),
    },
  ],
  [
    {
      top: <img style={{ width: "6em" }} src={TalenyLogo} alt="Taleny Logo" />,
      content: (
        <>
          <h3>Futuros Inteligentes</h3>
          <p>
            Brindamos una charla para mamás y papás sobre Inteligencia
            Artificial y lo que significa para sus hijas e hijos.
          </p>
        </>
      ),
    },
    {
      top: (
        <img
          style={{ width: "6em" }}
          src={ChangeMakerLogo}
          alt="Change Maker Logo"
        />
      ),
      content: (
        <>
          <h3>Encontrando propósito</h3>
          <p>
            Jugamos con agentes de cambio universitarios en el Changemaker Day,
            encontrando propósito con memorias.
          </p>
        </>
      ),
    },
    {
      top: (
        <img
          style={{ width: "6em" }}
          src={MakerFaireLogo}
          alt="Maker Faire Logo"
        />
      ),
      content: (
        <>
          <h3>Historias de Juego</h3>
          <p>
            Dimos una charla en la Jalisco Maker Faire, compartiendo nuestro
            trabajo en comunidades de juego.
          </p>
        </>
      ),
    },
  ],
]

const reasonsTitle = (
  <h2 className="w-normal text-right">
    ¿<span className="s-italic">Cómo hemos</span>{" "}
    <span className="c-turquoise w-bold">jugado</span> en{" "}
    <span className="w-bold">equipo</span>?
  </h2>
)

const MeiquerLabsHistoriasPage = props => {
  const data = useStaticQuery(graphql`
    query MeiquerLabsHistoriasQuery {
      file(relativePath: { eq: "meiquer-fonnor.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout location={props.location}>
      <SEO title="Meiquer Labs Historias" />
      <MainBanner
        image={data.file.childImageSharp.fluid}
        content={mainBannerContent}
      />
      <ConceptGrid conceptChunks={reasons} title={reasonsTitle} />
    </Layout>
  )
}

export default MeiquerLabsHistoriasPage
